import React, { useState, useMemo, Suspense, useEffect } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import styles from './WithdrawalContainer.pcss';
import { getDataForSearch } from './data';
import Withdrawals from './WithdrawalList/WithdrawalList';
import WithdrawalFilters, { Filters } from './WithdrawalFilters/WithdrawalFilters';

import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import { WithdrawalContainerQuery as QueryType } from 'GraphTypes/WithdrawalContainerQuery.graphql';

export const WithdrawalContainerQuery = graphql`
  query WithdrawalContainerQuery(
    $dateFrom: DateTime
    $dateTo: DateTime
    $currencies: [Currency!]
    $statuses: [Payment_WithdrawalStatus!]
    $paymentMethodTypes: [Payment_PaymentMethodType!]
    $accountQuery: String
    $profileQuery: String
    $paymentDataQuery: String
    $campaignQuery: String
    $w9WarningLevels: [W9WarningLevel!]
  ) {
    ...WithdrawalList_withdrawals
      @arguments(
        dateFrom: $dateFrom
        dateTo: $dateTo
        currencies: $currencies
        statuses: $statuses
        paymentMethodTypes: $paymentMethodTypes
        accountQuery: $accountQuery
        profileQuery: $profileQuery
        paymentDataQuery: $paymentDataQuery
        campaignQuery: $campaignQuery
        w9WarningLevels: $w9WarningLevels
      )
  }
`;

const defaultParams = {
  dateFrom: null,
  dateTo: null,
  currencies: [],
  statuses: [],
  paymentMethodTypes: [],
  w9WarningLevels: [],
  accountQuery: '',
  profileQuery: '',
  paymentDataQuery: '',
  campaignQuery: '',
};

const WithdrawalContainer = () => {
  const [filters, setFilters] = useState<Filters>(defaultParams);

  const [queryReference, loadQuery] = useQueryLoader<QueryType>(WithdrawalContainerQuery);

  useEffect(() => {
    loadQuery(filters, {
      fetchPolicy: 'network-only',
    });
  }, [
    filters.dateFrom,
    filters.dateTo,
    filters.currencies,
    filters.statuses,
    filters.paymentMethodTypes,
    filters.w9WarningLevels,
    filters.accountQuery,
    filters.profileQuery,
    filters.paymentDataQuery,
    filters.campaignQuery,
  ]);

  const handleFiltersChange = (filters: Filters) => {
    setFilters(filters);
  };

  const handleParamsClear = () => {
    setFilters(defaultParams);
  };

  const dataForSearch = useMemo(() => {
    return getDataForSearch(filters);
  }, [filters]);

  return (
    <div className={styles.root}>
      <Text
        type="d2"
        msg="admin_withdrawals.title"
        className={styles.title}
        data-test="withdrawalContainer:text:title"
      />
      <WithdrawalFilters
        filters={filters}
        onFiltersChange={handleFiltersChange}
        onFiltersClear={handleParamsClear}
      />
      <Suspense fallback={<Spinner className={styles.spinner} />}>
        {queryReference && <Withdrawals {...dataForSearch} queryReference={queryReference} />}
      </Suspense>
    </div>
  );
};

export default WithdrawalContainer;
