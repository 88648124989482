import React from 'react';

import styles from './WithdrawalListBar.pcss';

import Text from 'Components/ui/Text/Text';

const WithdrawalListBar = () => {
  return (
    <div className={styles.root}>
      <Text msg="bar.requestId" />
      <Text msg="bar.account" />
      <Text msg="bar.profile" />
      <Text msg="bar.amount" />
      <Text msg="bar.psp" />
      <Text msg="bar.payment_method" />
      <Text msg="bar.transfer" />
    </div>
  );
};

export default WithdrawalListBar;
