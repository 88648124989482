import React, { useState, useContext, useRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import Profiles from '../Profiles/Profiles';
import TransferStatus from '../TransferStatus/TransferStatus';
import AdminComment from '../AdminComment/AdminComment';

import styles from './WithdrawalItem.pcss';

import { modalName } from 'Types/modals';
import { createWithdrawalDate } from 'Util/dateCreator';
import { WithdrawalItem_withdrawal$key } from 'GraphTypes/WithdrawalItem_withdrawal.graphql';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';
import { createSum } from 'Util/numberFormatter';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { copyText } from 'Util/copyText';
import Icon from 'Atoms/Icon/Icon';

interface Props {
  withdrawal: WithdrawalItem_withdrawal$key;
}

const WithdrawalItem: React.FC<Props> = (props) => {
  const { withdrawal } = props;

  const [currentTransactionId, setCurrentTransactionId] = useState<string>();
  const [currentTransactionProcess, setCurrentTransactionProcess] = useState<boolean>(false);
  const currentTransactionProvider = useRef();

  const currentTransactionData = {
    currentTransactionId,
    setCurrentTransactionId,
    currentTransactionProcess,
    setCurrentTransactionProcess,
    currentTransactionProvider,
  };

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const data = useFragment(
    graphql`
      fragment WithdrawalItem_withdrawal on Payment_Withdrawal {
        id
        createdAt
        currency
        amount
        status
        paymentServiceProvider
        paymentMethod {
          type
          ... on Payment_Paypal {
            email
          }
          w9WarningLevel
        }
        ...AdminComment_comment
        ...TransferStatus_status
        paymentAccount {
          ...Profiles_paymentAccount
          owner {
            email
            contractorProfile {
              firstName
              lastName
            }
          }
        }
      }
    `,
    withdrawal
  );

  const id = data.id;
  const status = data.status;
  const createdAt = data.createdAt;
  const currency = data.currency;
  const paymentAccount = data.paymentAccount;
  const amount = data.amount;
  const w9WarningLevel = data.paymentMethod?.w9WarningLevel;
  const methodType = data.paymentMethod?.type;
  const email = paymentAccount.owner?.email;
  const firstName = paymentAccount.owner?.contractorProfile?.firstName;
  const lastName = paymentAccount.owner?.contractorProfile?.lastName;
  const profileName = `${firstName} ${lastName}`;

  // TODO: remove old modal
  const handleDetailsClick = () => {
    setCurrentTransactionId(id);
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: {
        name: modalName.WITHDRAWAL_REQUEST,
        attach: { id, setCurrentTransactionProcess },
      },
    });
  };

  const handleIdCopy = () => {
    copyText(id);
  };

  const handleEmailCopy = () => {
    copyText(email);
  };

  const handleProfileNameCopy = () => {
    copyText(profileName);
  };

  let w9Data = { tooltipMsg: '', color: '' };

  if (w9WarningLevel === 'HIGH') {
    w9Data = { color: 'red', tooltipMsg: 'admin_withdrawals.payout_limit.failed' };
  } else if (w9WarningLevel === 'MEDIUM') {
    w9Data = { color: 'yellow', tooltipMsg: 'admin_withdrawals.payout_limit.warning' };
  } else if (w9WarningLevel === 'LOW') {
    w9Data = { color: 'green', tooltipMsg: 'admin_withdrawals.payout_limit.accepted' };
  }

  return (
    <div className={classnames(styles.root, { [styles.failed]: status === 'FAILED' })}>
      <div>
        <Tooltip id={id} place="top" tooltipMsg="admin_withdrawals.copy">
          <Text text={id} className={styles.cuttedText} handleClick={handleIdCopy} />
        </Tooltip>
        <Text type="sm" color="grey" text={createWithdrawalDate(createdAt)} />
      </div>
      <div>
        <Tooltip id={`${id}-${email}`} place="left" tooltipMsg="admin_withdrawals.copy">
          <Text text={email} className={styles.cuttedText} handleClick={handleEmailCopy} />
        </Tooltip>
        <Tooltip id={`${id}-${profileName}`} place="left" tooltipMsg="admin_withdrawals.copy">
          <Text
            type="sm"
            color="grey"
            text={profileName}
            className={styles.cuttedText}
            handleClick={handleProfileNameCopy}
          />
        </Tooltip>
      </div>
      <Profiles paymentAccount={paymentAccount} />
      <div className={styles.amountWrap}>
        <Text text={createSum(Math.abs(amount), currency)} />
        {w9Data.color && (
          <Tooltip id={`${id}-w9`} place="top" tooltipMsg={w9Data.tooltipMsg}>
            <Icon size="sm" name="w9" color={w9Data.color} />
          </Tooltip>
        )}
      </div>
      <Text msg={`payuot_method.${methodType?.toLowerCase()}`} />
      <TextButton
        color="purple"
        msg="admin_withdrawals.payment_details"
        onClick={handleDetailsClick}
      />
      <TransferStatus status={data} {...currentTransactionData} />
      <AdminComment comment={data} />
    </div>
  );
};

export default WithdrawalItem;
