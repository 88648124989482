import React, { useCallback, useState } from 'react';
import xor from 'lodash/xor';
import debounce from 'lodash/debounce';
import { useIntl } from 'react-intl';

import styles from './WithdrawalFilters.pcss';
import { getFiltersData, getWarningLevels } from './utils';

import DatePicker from 'Components/ui/DatePicker/DatePicker';
import TextButton from 'Components/ui/TextButton/TextButton';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import {
  Currency,
  Payment_WithdrawalStatus,
  W9WarningLevel,
  Payment_PaymentMethodType,
} from 'GraphTypes/WithdrawalContainerQuery.graphql';

export type Filters = {
  dateFrom: Date | 'string' | null;
  dateTo: Date | 'string' | null;
  currencies: Currency[];
  statuses: Payment_WithdrawalStatus[];
  paymentMethodTypes: Payment_PaymentMethodType[];
  w9WarningLevels: W9WarningLevel[];
  accountQuery: string;
  profileQuery: string;
  paymentDataQuery: string;
  campaignQuery: string;
};

interface Props {
  filters: Filters;
  onFiltersChange: (filters: Filters) => void;
  onFiltersClear: () => void;
}

const WithdrawalFilters: React.FC<Props> = (props) => {
  const { filters, onFiltersChange, onFiltersClear } = props;

  const [w9WarningLevel, setW9WarningLevels] = useState<string | undefined>();

  const intl = useIntl();

  const filtersData = getFiltersData(filters, w9WarningLevel);

  const handleCurrencyChange = (id: string) => {
    const newCurrenciesValue = xor(filters.currencies || [], [id]);
    onFiltersChange({ ...filters, currencies: newCurrenciesValue as Currency[] });
  };

  const handleStatusesChange = (id: string) => {
    const newStatusesValue = xor(filters.statuses || [], [id]);
    onFiltersChange({ ...filters, statuses: newStatusesValue as Payment_WithdrawalStatus[] });
  };

  const handleW9WarningLevelsChange = (id: string) => {
    setW9WarningLevels(w9WarningLevel === id ? undefined : id);
    const newW9WarningLevelsValue = getWarningLevels(id);
    onFiltersChange({ ...filters, w9WarningLevels: newW9WarningLevelsValue as W9WarningLevel[] });
  };

  const handleAccountChange = useCallback(
    debounce((value) => {
      onFiltersChange({ ...filters, accountQuery: value });
    }, 500),
    [filters]
  );

  const handleProfileChange = useCallback(
    debounce((value) => {
      onFiltersChange({ ...filters, profileQuery: value });
    }, 500),
    [filters]
  );

  const handlePaymentDataChange = useCallback(
    debounce((value) => {
      onFiltersChange({ ...filters, paymentDataQuery: value });
    }, 500),
    [filters]
  );

  const handleCampaignChange = useCallback(
    debounce((value) => {
      onFiltersChange({ ...filters, campaignQuery: value });
    }, 500),
    [filters]
  );
  const handleDateFromChange = (date: Date | null) => {
    onFiltersChange({ ...filters, dateFrom: date });
  };

  const handleDateToChange = (date: Date | null) => {
    onFiltersChange({ ...filters, dateTo: date });
  };

  const handleDateFromClear = () => {
    onFiltersChange({ ...filters, dateFrom: null });
  };

  const handleDateToClear = () => {
    onFiltersChange({ ...filters, dateTo: null });
  };

  const handleMethodsChange = (id: string) => {
    const newMethodsValue = xor(filters.paymentMethodTypes || [], [id]);
    onFiltersChange({
      ...filters,
      paymentMethodTypes: newMethodsValue as Payment_PaymentMethodType[],
    });
  };

  const handleParamsClear = () => {
    setW9WarningLevels(undefined);
    onFiltersClear();
  };

  const hasCurrencies = filters.currencies.length > 0;
  const hasStatuses = filters.statuses.length > 0;
  const hasPaymentMethods = filters.paymentMethodTypes.length > 0;
  const hasW9WarningLevels = !!filters.w9WarningLevels;

  return (
    <div className={styles.root}>
      <TextButton
        color="grey"
        msg="general.clear_all"
        onClick={handleParamsClear}
        className={styles.clearParams}
        data-test="withdrawalFilters:textButton:clearAll"
      />
      <ProgressiveInput
        type="input"
        titleMsg="search_section.form.account"
        inputProps={{
          value: filters.accountQuery,
          bordered: true,
          placeholderMsg: 'search_section.form.account.placeholder',
          handleChange: handleAccountChange,
        }}
        className={styles.input}
        isDirty={!!filters.accountQuery}
      />
      <ProgressiveInput
        type="input"
        titleMsg="search_section.form.profile"
        inputProps={{
          value: filters.profileQuery,
          bordered: true,
          placeholderMsg: 'search_section.form.profile.placeholder',
          handleChange: handleProfileChange,
        }}
        className={styles.input}
        isDirty={!!filters.profileQuery}
      />
      <ProgressiveInput
        type="input"
        titleMsg="search_section.form.payment_data"
        inputProps={{
          value: filters.paymentDataQuery,
          bordered: true,
          placeholderMsg: 'search_section.form.payment_data.placeholder',
          handleChange: handlePaymentDataChange,
        }}
        className={styles.input}
        isDirty={!!filters.paymentDataQuery}
      />
      <ProgressiveInput
        type="input"
        titleMsg="search_section.form.campaign_data"
        inputProps={{
          value: filters.campaignQuery,
          bordered: true,
          placeholderMsg: 'search_section.form.campaign_data.placeholder',
          handleChange: handleCampaignChange,
        }}
        className={styles.input}
        isDirty={!!filters.campaignQuery}
      />
      <DatePicker
        inputClassName={styles.date}
        customInputProps={{
          bordered: true,
          rightIcon: 'Calendar-schedule',
        }}
        onResetValue={handleDateFromClear}
        reactDatePickerProps={{
          selected: filters.dateFrom,
          placeholderText: intl.formatMessage({ id: 'search_section.form.dateFrom' }),
          onChange: handleDateFromChange,
        }}
      />
      <DatePicker
        inputClassName={styles.date}
        onResetValue={handleDateToClear}
        customInputProps={{
          bordered: true,
          rightIcon: 'Calendar-schedule',
        }}
        reactDatePickerProps={{
          selected: filters.dateTo,
          placeholderText: intl.formatMessage({ id: 'search_section.form.dateTo' }),
          onChange: handleDateToChange,
        }}
      />
      <ProgressiveInput
        type="checkboxSelect"
        inputProps={{
          items: filtersData.currencies,
          bordered: true,
          placeholderMsg: 'search_section.form.currency',
          handleClick: handleCurrencyChange,
          hideByAlphabet: true,
          handleClear: hasCurrencies
            ? () => {
                onFiltersChange({ ...filters, currencies: [] });
              }
            : undefined,
        }}
        isDirty={hasCurrencies}
      />
      <ProgressiveInput
        type="checkboxSelect"
        inputProps={{
          items: filtersData.statuses,
          bordered: true,
          placeholderMsg: 'search_section.form.withdrawal.statuses',
          handleClick: handleStatusesChange,
          hideByAlphabet: true,
          handleClear: hasStatuses
            ? () => {
                onFiltersChange({ ...filters, statuses: [] });
              }
            : undefined,
        }}
        isDirty={hasStatuses}
      />
      <ProgressiveInput
        type="checkboxSelect"
        inputProps={{
          items: filtersData.paymentMethodTypes,
          bordered: true,
          placeholderMsg: 'search_section.form.payment_method_psp',
          handleClick: handleMethodsChange,
          hideByAlphabet: true,
          handleClear: hasPaymentMethods
            ? () => {
                onFiltersChange({ ...filters, paymentMethodTypes: [] });
              }
            : undefined,
        }}
        isDirty={hasPaymentMethods}
      />
      <ProgressiveInput
        type="radio"
        inputProps={{
          items: filtersData.w9WarningLevels,
          bordered: true,
          placeholderMsg: 'search_section.form.w9form',
          handleClick: handleW9WarningLevelsChange,
          hideByAlphabet: true,
          place: 'bottomRight',
          handleClear: hasW9WarningLevels
            ? () => {
                setW9WarningLevels(undefined);
                onFiltersChange({ ...filters, w9WarningLevels: [] });
              }
            : undefined,
        }}
        isDirty={!!w9WarningLevel}
      />
    </div>
  );
};

export default WithdrawalFilters;
