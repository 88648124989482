import React from 'react';
import { graphql, PreloadedQuery, usePaginationFragment, usePreloadedQuery } from 'react-relay';

import { Filters } from '../WithdrawalFilters/WithdrawalFilters';
import { WithdrawalContainerQuery } from '../WithdrawalContainer';

import WithdrawalListBar from './WithdrawalListBar/WithdrawalListBar';
import styles from './WithdrawalList.pcss';
import WithdrawalItem from './WithdrawalItem/WithdrawalItem';

import { createSum } from 'Util/numberFormatter';
import Text from 'Components/ui/Text/Text';
import LoaderHandlerWithHooks from 'Organisms/LoaderHandler/NewLoaderHandler';
import { WithdrawalContainerQuery as QueryType } from 'GraphTypes/WithdrawalContainerQuery.graphql';
import { WithdrawalList_withdrawals$key } from 'GraphTypes/WithdrawalList_withdrawals.graphql';

const OFFSET = 70;
const COUNT = 20;

interface Props {
  queryReference: PreloadedQuery<QueryType>;
}

const WithdrawalList: React.FC<Props & Filters> = (props) => {
  const { queryReference } = props;
  const queryData = usePreloadedQuery<QueryType>(WithdrawalContainerQuery, queryReference);

  const fragmentData = usePaginationFragment<QueryType, WithdrawalList_withdrawals$key>(
    graphql`
      fragment WithdrawalList_withdrawals on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 15 }
        cursor: { type: "String" }
        dateFrom: { type: "DateTime" }
        dateTo: { type: "DateTime" }
        currencies: { type: "[Currency!]" }
        statuses: { type: "[Payment_WithdrawalStatus!]" }
        paymentMethodTypes: { type: "[Payment_PaymentMethodType!]" }
        accountQuery: { type: "String" }
        profileQuery: { type: "String" }
        paymentDataQuery: { type: "String" }
        campaignQuery: { type: "String" }
        w9WarningLevels: { type: "[W9WarningLevel!]" }
      )
      @refetchable(queryName: "WithdrawalListPaginationList") {
        payment {
          withdrawals(
            first: $count
            after: $cursor
            dateFrom: $dateFrom
            dateTo: $dateTo
            currencies: $currencies
            statuses: $statuses
            paymentMethodTypes: $paymentMethodTypes
            accountQuery: $accountQuery
            profileQuery: $profileQuery
            paymentDataQuery: $paymentDataQuery
            campaignQuery: $campaignQuery
            w9WarningLevels: $w9WarningLevels
          ) @connection(key: "Admin_withdrawals", filters: []) {
            edges {
              node {
                id
                ...WithdrawalItem_withdrawal
              }
            }
            summary {
              usdTotal: totalAmount(currency: USD)
            }
          }
        }
      }
    `,
    queryData
  );

  const items = Array.from(fragmentData.data.payment?.withdrawals?.edges || []);

  const totalUSD = fragmentData.data.payment?.withdrawals?.summary.usdTotal;

  const createTotal = (amount: number, currency: string) => {
    return (
      <div className={styles.totalItem}>
        <Text text={`Total ${currency}: `} data-test="withdrawalList:text:unknown" />
        <Text
          weight="bold"
          text={createSum(amount, currency)}
          data-test="withdrawalList:text:unknown"
        />
      </div>
    );
  };

  return (
    <div className={styles.list}>
      <LoaderHandlerWithHooks relay={fragmentData} offset={OFFSET} count={COUNT} items={items}>
        {items.length > 0 && <WithdrawalListBar />}
        <div>
          {items.map((item) => {
            if (!item?.node) return null;
            const { id } = item.node;
            return <WithdrawalItem key={id} withdrawal={item.node} />;
          })}
        </div>
      </LoaderHandlerWithHooks>
      <div className={styles.totalsWrap}>
        <div className={styles.totals}>
          {Number(totalUSD) > 0 && createTotal(Number(totalUSD), 'USD')}
        </div>
      </div>
    </div>
  );
};

export default WithdrawalList;
