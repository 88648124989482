/**
 * @generated SignedSource<<57fc5e601a46639f6f49437176cc16ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD";
export type Payment_PaymentMethodType = "CARD" | "CARDPAY" | "GAMEMONEY" | "PAYPAL" | "QIWI" | "WIRE" | "ZELLE";
export type Payment_PaymentServiceProvider = "QIWI" | "UNLIMINT";
export type Payment_WithdrawalStatus = "CANCELED" | "CONFIRMED" | "FAILED" | "PROCESSING" | "UNSENT";
export type W9WarningLevel = "HIGH" | "LOW" | "MEDIUM" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type WithdrawalItem_withdrawal$data = {
  readonly amount: number;
  readonly createdAt: any;
  readonly currency: Currency;
  readonly id: string;
  readonly paymentAccount: {
    readonly owner: {
      readonly contractorProfile: {
        readonly firstName: string | null | undefined;
        readonly lastName: string | null | undefined;
      } | null | undefined;
      readonly email: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"Profiles_paymentAccount">;
  };
  readonly paymentMethod: {
    readonly email?: string | null | undefined;
    readonly type: Payment_PaymentMethodType | null | undefined;
    readonly w9WarningLevel: W9WarningLevel;
  } | null | undefined;
  readonly paymentServiceProvider: Payment_PaymentServiceProvider | null | undefined;
  readonly status: Payment_WithdrawalStatus;
  readonly " $fragmentSpreads": FragmentRefs<"AdminComment_comment" | "TransferStatus_status">;
  readonly " $fragmentType": "WithdrawalItem_withdrawal";
};
export type WithdrawalItem_withdrawal$key = {
  readonly " $data"?: WithdrawalItem_withdrawal$data;
  readonly " $fragmentSpreads": FragmentRefs<"WithdrawalItem_withdrawal">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WithdrawalItem_withdrawal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentServiceProvider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "paymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/)
          ],
          "type": "Payment_Paypal",
          "abstractKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "w9WarningLevel",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminComment_comment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TransferStatus_status"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Payment_Account",
      "kind": "LinkedField",
      "name": "paymentAccount",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Profiles_paymentAccount"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "owner",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ContractorProfile",
              "kind": "LinkedField",
              "name": "contractorProfile",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Payment_Withdrawal",
  "abstractKey": null
};
})();

(node as any).hash = "50032326dc4effe03d434e81f8044bc5";

export default node;
