/**
 * @generated SignedSource<<4557793548ff945958f7cc20ee2e2e33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WithdrawalList_withdrawals$data = {
  readonly payment: {
    readonly withdrawals: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"WithdrawalItem_withdrawal">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly summary: {
        readonly usdTotal: number | null | undefined;
      };
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "WithdrawalList_withdrawals";
};
export type WithdrawalList_withdrawals$key = {
  readonly " $data"?: WithdrawalList_withdrawals$data;
  readonly " $fragmentSpreads": FragmentRefs<"WithdrawalList_withdrawals">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "payment",
  "withdrawals"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "accountQuery"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "campaignQuery"
    },
    {
      "defaultValue": 15,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currencies"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dateFrom"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dateTo"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "paymentDataQuery"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "paymentMethodTypes"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "profileQuery"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "statuses"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "w9WarningLevels"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./WithdrawalListPaginationList.graphql')
    }
  },
  "name": "WithdrawalList_withdrawals",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Payment_Query",
      "kind": "LinkedField",
      "name": "payment",
      "plural": false,
      "selections": [
        {
          "alias": "withdrawals",
          "args": null,
          "concreteType": "Payment_WithdrawalConnection",
          "kind": "LinkedField",
          "name": "__Admin_withdrawals_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Payment_WithdrawalEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Payment_Withdrawal",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "WithdrawalItem_withdrawal"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Payment_WithdrawalConnectionSummary",
              "kind": "LinkedField",
              "name": "summary",
              "plural": false,
              "selections": [
                {
                  "alias": "usdTotal",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "currency",
                      "value": "USD"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "totalAmount",
                  "storageKey": "totalAmount(currency:\"USD\")"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "42feb62012cc9993d0ecdd0eeaadedf1";

export default node;
